<template>
  <div
    class="row justify-center items-start bg-indigo-1"
    style="min-height: calc(100vh - 83px)"
  >
    <q-card flat bordered style="width:300px">
      <q-tabs
        dense
        indicator-color="orange"
        align="center"
        inline-label
        active-color="white"
        class="bg-blue-12 text-blue-11 shadow-2"
        v-model="tab"
      >
        <q-tab label="Rekap" name="rekap"></q-tab>
        <q-tab label="Guru" name="guru"></q-tab>
      </q-tabs>
      <q-tab-panels v-model="tab">
        <q-tab-panel name="rekap" class="q-pa-none">
          <q-card-section class="q-gutter-md">
            <q-select
              outlined
              v-model="selJenjang"
              label="Jenjang"
              :options="jenjang"
            />
          </q-card-section>
          <q-card-section
            class="q-pb-sm row justify-between items-center"
            v-if="range"
          >
            <q-input
              dense
              readonly
              outlined
              stack-label
              label="Awal"
              :value="momentFilter(range.from)"
              style="max-width:125px"
            ></q-input>
            <a>
              <b>-</b>
            </a>
            <q-input
              dense
              readonly
              outlined
              stack-label
              label="Akhir"
              :value="momentFilter(range.to)"
              style="max-width:125px"
            ></q-input>
            <q-btn
              unelevated
              class="full-width q-mt-sm"
              color="primary"
              icon="date_range"
              label="Atur Range"
              @click="showDate = true"
            ></q-btn>
          </q-card-section>
          <q-markup-table
            dense
            wrap-cells
            class="bg-grey-4"
            style="min-height:calc(100vh - 320px)"
          >
            <tbody class="bg-white" v-if="filteredAbsen">
              <tr>
                <td>Total Hari</td>
                <td>:</td>
                <td>{{ filteredAbsen.total_hari }} Hari</td>
              </tr>
              <tr>
                <td>Total Absensi</td>
                <td>:</td>
                <td>{{ filteredAbsen.total }} Input Absensi</td>
              </tr>
              <tr>
                <td>Absensi Pertama</td>
                <td>:</td>
                <td>{{ momentFilter(filteredAbsen.absen_awal) }}</td>
              </tr>
              <tr>
                <td>Absensi Terakhir</td>
                <td>:</td>
                <td>{{ momentFilter(filteredAbsen.absen_akhir) }}</td>
              </tr>
              <tr>
                <td>Hadir</td>
                <td>:</td>
                <td>
                  {{ filteredAbsen.hadir }} ({{ filteredAbsen.hadir_persen }}%)
                </td>
              </tr>
              <tr>
                <td>Sakit</td>
                <td>:</td>
                <td>
                  {{ filteredAbsen.sakit }} ({{ filteredAbsen.sakit_persen }}%)
                </td>
              </tr>
              <tr>
                <td>Izin</td>
                <td>:</td>
                <td>
                  {{ filteredAbsen.izin }} ({{ filteredAbsen.izin_persen }}%)
                </td>
              </tr>
              <tr>
                <td>Alfa</td>
                <td>:</td>
                <td>
                  {{ filteredAbsen.alfa }} ({{ filteredAbsen.alfa_persen }}%)
                </td>
              </tr>
            </tbody>
          </q-markup-table>
        </q-tab-panel>
        <q-tab-panel name="guru" class="q-pa-none">
          <q-card-section class="column">
            <q-btn
              unelevated
              class="full-width q-mb-sm"
              color="primary"
              label="FILTER"
              @click="showFilter = true"
            ></q-btn>
            <q-input
              outlined
              readonly
              class=" q-mb-sm"
              label="Tanggal"
              v-model="filGuru.date"
            >
              <template v-slot:append>
                <q-icon name="event" class="cursor-pointer">
                  <q-popup-proxy
                    ref="qDateProxy"
                    transition-show="scale"
                    transition-hide="scale"
                  >
                    <q-date
                      v-model="filGuru.date"
                      :options="optGuru.date"
                      @input="getAbsenGuru"
                    >
                      <div class="row items-center justify-end">
                        <q-btn
                          v-close-popup
                          label="Close"
                          color="primary"
                          flat
                        />
                      </div>
                    </q-date>
                  </q-popup-proxy>
                </q-icon>
              </template>
            </q-input>
            <q-input
              outlined
              label="Cari Nama Guru/Mapel/Kelas"
              v-model="filGuru.searchTerm"
            ></q-input>
          </q-card-section>
          <q-list separator class="bg-grey-2">
            <q-expansion-item
              v-for="(val, i) in filteredGuru"
              :key="i"
              class="bg-grey-4"
            >
              <template v-slot:header>
                <q-item-section>
                  <q-item-label
                    class="text-body1 text-bold"
                    v-html="val.cur_guru"
                  ></q-item-label>
                  <q-item-label
                    >Mata Pelajaran : <span v-html="val.cur_mapel"></span>
                  </q-item-label>
                  <q-item-label
                    >Rombel : <span v-html="val.cur_rombel"></span>
                  </q-item-label>
                </q-item-section>
              </template>

              <q-card>
                <q-markup-table flat>
                  <tr>
                    <td class="text-bold">Total</td>
                    <td class="text-bold">:</td>
                    <td class="text-bold">{{ val.banyak }}</td>
                  </tr>
                  <tr>
                    <td>Hadir</td>
                    <td>:</td>
                    <td>{{ val.detail_kehadiran.hadir }}</td>
                  </tr>
                  <tr>
                    <td>Sakit</td>
                    <td>:</td>
                    <td>{{ val.detail_kehadiran.sakit }}</td>
                  </tr>
                  <tr>
                    <td>Izin</td>
                    <td>:</td>
                    <td>{{ val.detail_kehadiran.izin }}</td>
                  </tr>
                  <tr>
                    <td>Alfa</td>
                    <td>:</td>
                    <td>{{ val.detail_kehadiran.alfa }}</td>
                  </tr>
                </q-markup-table>
              </q-card>
            </q-expansion-item>
          </q-list>
        </q-tab-panel>
      </q-tab-panels>
    </q-card>
    <q-dialog v-model="showFilter" position="bottom">
      <q-card style="width:300px;">
        <q-card-section class="row justify-between items-center">
          <a class="text-h6 text-bold">Filter</a>
          <q-btn flat icon="close" color="primary"></q-btn>
        </q-card-section>
        <q-card-section class="column q-gutter-sm">
          <q-select
            outlined
            label="Jenjang"
            :options="$LIST_JENJANG"
            v-model="filGuru.jenjang"
            @input="getTahunAjar"
          ></q-select>
          <q-select
            outlined
            label="Tingkat"
            :options="optGuru.tingkat"
            v-model="filGuru.tingkat"
            @input="getAbsenDate()"
          ></q-select>
          <q-select
            outlined
            map-options
            emit-value
            label="Tahun Ajaran"
            :options="optGuru.tahun_ajar"
            v-model="filGuru.tahun_ajar"
            @input="getAbsenDate()"
          ></q-select>
        </q-card-section>
      </q-card>
    </q-dialog>
    <q-dialog v-model="showDate">
      <q-card>
        <q-card-section>
          <q-date v-model="range" range />
        </q-card-section>
        <q-card-actions align="right">
          <q-btn flat label="Close" color="primary" v-close-popup></q-btn>
          <q-btn
            unelevated
            label="RESET"
            color="primary"
            @click="range = { from: null, to: null }"
          ></q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      tab: "guru",
      showDate: false,
      showFilter: false,

      selJenjang: "SEMUA",
      jenjang: [],

      range: { from: null, to: null },

      data_absen: [],

      filGuru: {
        searchTerm: "",
        jenjang: null,
        tingkat: null,
        date: null,
        tahun_ajar: null,
      },
      optGuru: {
        tingkat: [],
        date: [],
        tahun_ajar: [],
      },
      data_guru: [],
    };
  },

  computed: {
    filteredAbsen() {
      let temp = JSON.parse(JSON.stringify(this.data_absen));

      if (this.selJenjang != "SEMUA") {
        temp = temp.filter((val) => {
          return val.jenjang == this.selJenjang;
        });
      }

      if (this.range.from && this.range.to) {
        temp = temp.filter((val) => {
          return (
            moment(val.waktu) >= moment(this.range.from) &&
            moment(val.waktu) <= moment(this.range.to)
          );
        });
      }

      let data = {};
      data.total = temp.length;
      let hadir = temp.filter(function(x) {
        return x.status == "Hadir";
      });
      data.hadir = hadir.length;
      data.hadir_persen = parseFloat(
        (parseFloat(data.hadir) / parseFloat(data.total)) * 100
      ).toFixed(2);

      let sakit = temp.filter(function(x) {
        return x.status == "Sakit";
      });
      data.sakit = sakit.length;
      data.sakit_persen = parseFloat(
        (parseFloat(data.sakit) / parseFloat(data.total)) * 100
      ).toFixed(2);

      let izin = temp.filter(function(x) {
        return x.status == "Izin";
      });
      data.izin = izin.length;
      data.izin_persen = parseFloat(
        (parseFloat(data.izin) / parseFloat(data.total)) * 100
      ).toFixed(2);

      let alfa = temp.filter(function(x) {
        return x.status == "Alfa";
      });
      data.alfa = alfa.length;
      data.alfa_persen = parseFloat(
        (parseFloat(data.alfa) / parseFloat(data.total)) * 100
      ).toFixed(2);

      data.total_hari = [
        ...new Set(temp.map((val) => moment(val.waktu).format("DD MM YY"))),
      ].length;

      if (temp.length > 0) {
        data.absen_awal = moment(temp[0].waktu);
        data.absen_akhir = moment(temp[temp.length - 1].waktu);
      } else {
        data.absen_awal = null;
        data.absen_akhir = null;
      }

      return data;
    },
    filteredGuru() {
      let temp = JSON.parse(JSON.stringify(this.data_guru));
      for (let i in temp) {
        temp[i].id = i;
      }

      if (this.filGuru.searchTerm != "") {
        let all = [];
        let guru = temp.filter((val) => {
          return (
            val.cur_guru
              .toLowerCase()
              .indexOf(this.filGuru.searchTerm.toLowerCase()) != -1
          );
        });
        let kelas = temp.filter((val) => {
          return (
            val.cur_rombel
              .toLowerCase()
              .indexOf(this.filGuru.searchTerm.toLowerCase()) != -1
          );
        });
        let mapel = temp.filter((val) => {
          return (
            val.cur_mapel
              .toLowerCase()
              .indexOf(this.filGuru.searchTerm.toLowerCase()) != -1
          );
        });

        all = all.concat(guru);
        all = all.concat(kelas);
        all = all.concat(mapel);

        let mapidx = all.map((o) => o.id);
        temp = all.filter(({ id }, index) => !mapidx.includes(id, index + 1));

        //marking
        let items = [];
        for (let item of temp) {
          //labeling mapel
          let idx = item.cur_mapel
            .toLowerCase()
            .indexOf(this.filGuru.searchTerm.toLowerCase());
          if (idx != -1) {
            let x1 = item.cur_mapel.split("");
            x1[idx] = `<mark>${x1[idx]}`;
            x1[this.filGuru.searchTerm.length + idx - 1] = `${
              x1[this.filGuru.searchTerm.length + idx - 1]
            }</mark>`;
            item.cur_mapel = x1.join("");
          }

          //labeling guru
          idx = item.cur_guru
            .toLowerCase()
            .indexOf(this.filGuru.searchTerm.toLowerCase());
          if (idx != -1) {
            let x1 = item.cur_guru.split("");
            x1[idx] = `<mark>${x1[idx]}`;
            x1[this.filGuru.searchTerm.length + idx - 1] = `${
              x1[this.filGuru.searchTerm.length + idx - 1]
            }</mark>`;
            item.cur_guru = x1.join("");
          }

          //labeling kelas
          idx = item.cur_rombel
            .toLowerCase()
            .indexOf(this.filGuru.searchTerm.toLowerCase());
          if (idx != -1) {
            let x1 = item.cur_rombel.split("");
            x1[idx] = `<mark>${x1[idx]}`;
            x1[this.filGuru.searchTerm.length + idx - 1] = `${
              x1[this.filGuru.searchTerm.length + idx - 1]
            }</mark>`;
            item.cur_rombel = x1.join("");
          }
          items.push(item);
        }
        temp = items;
      }

      return temp;
    },
  },

  async mounted() {
    this.$q.loading.show({ message: "Mohon Tunggu..." });
    //rekap
    this.jenjang = JSON.parse(JSON.stringify(this.$LIST_JENJANG));
    this.jenjang.unshift("SEMUA");
    await this.getAbsen();

    //guru
    this.filGuru.jenjang = this.$LIST_JENJANG[0];
    await this.getTahunAjar();

    this.$q.loading.hide();
  },
  methods: {
    momentFilter(somedate) {
      if (somedate) {
        return moment(somedate).format("DD MMM YYYY");
      } else {
        return "-";
      }
    },
    async getTahunAjar() {
      if (this.filGuru.jenjang == "MA") {
        this.optGuru.tingkat = [10, 11, 12];
        this.filGuru.tingkat = 10;
      } else {
        this.optGuru.tingkat = [7, 8, 9];
        this.filGuru.tingkat = 7;
      }
      let resp = await this.$http.get(
        `/statistik/rekap_absen/gettahunajar/${this.filGuru.jenjang}`
      );
      for (let item of resp.data) {
        item.label = `${item.nama} - Semester ${item.semester}`;
      }
      this.optGuru.tahun_ajar = resp.data;

      //refresh
      this.optGuru.date = [];
      this.data_guru = [];

      if (resp.data.length > 0) {
        this.filGuru.tahun_ajar = resp.data[0].value;
        await this.getAbsenDate();
      }
    },
    async getAbsenDate() {
      if (!this.filGuru.tahun_ajar || this.filGuru.tingkat)
        this.optGuru.date = [];
      let resp = await this.$http.get(
        `/statistik/rekap_absen/gettanggal/${this.filGuru.tahun_ajar}/${this.filGuru.tingkat}`
      );
      let dates = [];
      for (let item of resp.data) {
        dates.push(moment(item.waktu).format("YYYY/MM/DD"));
      }
      this.optGuru.date = dates;

      //refresh
      this.data_guru = [];

      if (dates.length > 0) {
        this.filGuru.date = dates[dates.length - 1];
        await this.getAbsenGuru();
      }
    },
    async getAbsenGuru() {
      let curDate = JSON.parse(JSON.stringify(this.filGuru)).date;
      curDate = moment(curDate).format("YYYY-MM-DD");
      let resp = await this.$http.get(
        `/statistik/rekap_absen/getabsenguru/${this.filGuru.tahun_ajar}/${this.filGuru.tingkat}/${curDate}`
      );
      this.data_guru = resp.data;
    },
    async getAbsen() {
      let resp = await this.$http.get(`/statistik/rekap_absen/getabsensi`);
      this.data_absen = resp.data;
    },
  },
};
</script>
